'use client';

import { IRootState } from '@/store';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '@/store/themeConfigSlice';
import IconSun from '../icon/icon-sun';
import IconMoon from '../icon/icon-moon';
import IconLaptop from '../icon/icon-laptop';
import { usePathname, useRouter } from 'next/navigation';

const Navbar = ({ showButtons, isLoading }: any) => {
    const router = useRouter();
    const pathname = usePathname();
    const dispatch = useDispatch();
    const [isSignInClicked, setIsSignInClicked] = useState(false);
    const [isSignInLoading, setIsSignInLoading] = useState(false);
    const [isSignUpClicked, setIsSignUpClicked] = useState(false);
    const [isSignUpLoading, setIsSignUpLoading] = useState(false);
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

    // console.log('Current Route:', pathname);

    useEffect(() => {
        if (pathname.includes('/sign-in')) {
            setIsSignUpLoading(false);
            setIsSignInLoading(true);
            setIsSignInClicked(true);
            setIsSignUpClicked(false);
        } else if (pathname.includes('/sign-up')) {
            setIsSignInLoading(false);
            setIsSignUpLoading(true);
            setIsSignUpClicked(true);
            setIsSignInClicked(false);
        }
    }, [router, pathname]);

    useEffect(() => {
        if (isLoading === false) {
            setIsSignInLoading(false);
            setIsSignUpLoading(false);
        }
    }, [isLoading]);

    return (
        <header className={`z-40 ${themeConfig.semidark && themeConfig.menu === 'horizontal' ? 'dark' : ''}`}>
            <div className="shadow-sm">
                <div className="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-black">
                    <div className="horizontal-logo flex items-center justify-between ltr:mr-2">
                        <Link href="/" className="main-logo flex shrink-0 items-center">
                            <Image className="inline w-8 ltr:-ml-1 rtl:-mr-1" src="/assets/images/logo.svg" alt="logo" width={80} height={20} />
                            <span className="align-middle text-2xl font-semibold transition-all duration-300 dark:text-white-light md:inline ltr:ml-1.5 rtl:mr-1.5">FleetNet</span>
                        </Link>
                    </div>

                    <div className="flex items-center space-x-1.5 dark:text-[#d0d2d6] lg:space-x-2 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse">
                        <div>
                            {themeConfig.theme === 'light' ? (
                                <button
                                    className={`${
                                        themeConfig.theme === 'light' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('dark'))}
                                >
                                    <IconSun />
                                </button>
                            ) : (
                                ''
                            )}
                            {themeConfig.theme === 'dark' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'dark' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('system'))}
                                >
                                    <IconMoon />
                                </button>
                            )}
                            {themeConfig.theme === 'system' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'system' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('light'))}
                                >
                                    <IconLaptop />
                                </button>
                            )}
                        </div>
                        {showButtons === false && <div style={{ height: '37px' }}></div>}
                        {showButtons === true && (
                            <>
                                <Link href="/sign-in">
                                    <button
                                        type="button"
                                        className={`btn btn-dark btn-sm hover:bg-blue-700 ${isSignInClicked ? 'bg-blue-500' : ''}`}
                                        onClick={() => {
                                            if (!pathname.includes('/sign-in')) {
                                                setIsSignInLoading(true);
                                            }
                                            setIsSignInClicked(true);
                                            setIsSignUpClicked(false);
                                        }}
                                        disabled={isSignInLoading}
                                    >
                                        {isSignInLoading ? (
                                            <span className="inline-block size-5 animate-spin rounded-full border-2 border-white border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 28 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="lucide lucide-log-in"
                                            >
                                                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                                                <polyline points="10 17 15 12 10 7" />
                                                <line x1="15" x2="3" y1="12" y2="12" />
                                            </svg>
                                        )}
                                        Sign In
                                    </button>
                                </Link>
                                <Link href="/sign-up">
                                    <button
                                        type="button"
                                        className={`btn btn-dark btn-sm hover:bg-blue-700 ${isSignUpClicked ? 'bg-blue-500' : ''}`}
                                        onClick={() => {
                                            setIsSignUpClicked(true);
                                            setIsSignInClicked(false);
                                            if (!pathname.includes('/sign-up')) {
                                                setIsSignUpLoading(true);
                                            }
                                        }}
                                        disabled={isSignUpLoading}
                                    >
                                        {isSignUpLoading ? (
                                            <span className="inline-block size-5 animate-spin rounded-full border-2 border-white border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 28 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="lucide lucide-user-plus"
                                            >
                                                <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                                <circle cx="9" cy="7" r="4" />
                                                <line x1="19" x2="19" y1="8" y2="14" />
                                                <line x1="22" x2="16" y1="11" y2="11" />
                                            </svg>
                                        )}
                                        Sign Up
                                    </button>
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
