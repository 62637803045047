/* eslint-disable @next/next/no-css-tags */
'use client';

import ContentAnimation from '@/components/layouts/content-animation';
import Footer from '@/components/layouts/footer';
import Header from '@/components/layouts/header';
import LoadingNew from '@/components/layouts/loadingnew';
import MainContainer from '@/components/layouts/main-container';
import Navbar from '@/components/layouts/navbar';
import NavbarPlant from '@/components/layouts/navbar-plantselection';
import Overlay from '@/components/layouts/overlay';
import ScrollToTop from '@/components/layouts/scroll-to-top';
import Setting from '@/components/layouts/setting';
import Sidebar from '@/components/layouts/sidebar';
import Portals from '@/components/portals';
import { Authorization, BKGROUND_IMAGE, COMPANY_LOGO_BLANK, ReleaseForms, cdate } from '@/constants/general';
import { showToast } from '@/constants/sweetalerts';
import { getUserById, updateSession } from '@/lib/actions/user.action';
import { useSessionStore } from '@/state/clerkssn';
import { useUser } from '@clerk/nextjs';
import axios, { AxiosError } from 'axios';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '@/store';
import '../../styles/global.css';
import { ErrorBoundary } from 'react-error-boundary';
import { useAppStore } from '@/state/company';
import { getAllVehicles } from '@/lib/actions/master/vehicle.action';

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
    const isDark = useSelector((state: IRootState) => state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode);
    const [initialLoading, setinitialLoading] = useState(true);
    const [initialUser, setinitialUser] = useState(false);

    const { user } = useUser();

    const pathname = usePathname();
    const router = useRouter();

    const skipLayoutPaths = ['/fleetnet/pdf-viewer'];

    const { count, clerkId, sessionId, updatedTime, systemName, update, setSession, resetSession } = useSessionStore();
    const { setSession: setSessionAppStore, clearSession: clearSessionAppStore, getSession } = useAppStore();

    const [superAdmin, setsuperAdmin] = useState<boolean>();
    const [admin, setadmin] = useState(false);
    const [testUser, setTestUser] = useState<boolean>(false);

    const [authorization, setAuthorization] = useState<Authorization[] | null>(null);
    const [forms, setForms] = useState<ReleaseForms[] | null>(null);
    const [companyId, setcompanyId] = useState(0);
    const [companyName, setcompanyName] = useState('');
    const [companyLogo, setcompanyLogo] = useState('');
    const [isProcessing, setisProcessing] = useState(true);
    const [isCookieExists, setisCookieExists] = useState(false);
    const [companyCount, setcompanyCount] = useState(0);
    const [isAlertFetched, setIsAlertFetched] = useState(false);
    const [isAlertShown, setIsAlertShown] = useState(false);
    const [alertData, setAlertData] = useState('');

    // const backgroundImage = !(user && companyId > 0) ? `url(${BKGROUND_IMAGE})` : '';
    const backgroundImage = !user && !skipLayoutPaths.includes(pathname) ? `url(${BKGROUND_IMAGE})` : '';

    const setCompanyInitially = async (clerkId: string) => {
        const cuser = await getUserById({
            clerkId,
        });

        if (cuser && cuser.isActive) {
            await setsuperAdmin(cuser.superAdmin);
            await setadmin(cuser.isAdmin);
            await setTestUser(cuser.testUser);
            // console.log('cuser.permissionSummary', cuser.superAdmin, cuser.isAdmin, cuser.permissionSummary, cuser.permissionRegisters);
            let transformedPermissions: Authorization[] | null = null;
            setForms((cuser as any).forms);

            if (cuser.permissionRegisters) {
                transformedPermissions = cuser.permissionRegisters.map((perm: any) => ({
                    id: perm.id,
                    formId: perm.formId,
                    name: perm.form.name,
                    group: perm.form.group,
                    subgroup: perm.form.subgroup,
                    type: {
                        add: perm.add,
                        edit: perm.edit,
                        delete: perm.delete,
                        view: perm.view,
                        viewatt: perm.viewatt,
                    },
                }));
                await setAuthorization(transformedPermissions || null);
            } else {
                await setAuthorization(null);
            }

            if (companyId === 0 && cuser.company) {
                setcompanyId(cuser.companyId ? cuser.companyId : 0);
                setcompanyName(cuser.company!.name ?? '');
                setcompanyLogo(cuser.company!.picture ?? COMPANY_LOGO_BLANK);

                const newSession = {
                    companyId: cuser.companyId ? cuser.companyId : 0,
                    companyName: cuser.company!.name ?? '',
                    companyLogo: cuser.company!.picture ?? COMPANY_LOGO_BLANK,
                    isSuperAdmin: cuser.superAdmin,
                    isAdmin: cuser.isAdmin,
                    testUser: cuser.testUser,
                    auth: transformedPermissions,
                    forms: (cuser as any).forms,
                };

                await setSessionAppStore(newSession);
                // console.log('Initially Called', companyId, newSession);

                return true;
            }

            return false;
        } else {
            setsuperAdmin(false);
            setcompanyId(0);
            setTestUser(false);

            return false;
        }
    };

    const getSelectedCompany = async (value: boolean) => {
        let type: boolean = false;
        value === false && setisProcessing(true);
        // console.log('isProcessing', isProcessing);
        try {
            const cuser = await getUserById({
                clerkId: user!.id,
            });

            const { data } = await axios.get('/api/company');
            // console.log('me', 'Hi there', data);
            if (cuser && data && data.companyId) {
                setcompanyId(parseInt(data.companyId));
                setcompanyName(data.companyName);
                setcompanyLogo(data.companyLogo ?? COMPANY_LOGO_BLANK);

                await setsuperAdmin(cuser.superAdmin);
                await setadmin(cuser.isAdmin);
                await setTestUser(cuser.testUser);

                let transformedPermissions: Authorization[] | null = null;
                setForms((cuser as any).forms);

                if (cuser.permissionRegisters) {
                    transformedPermissions = cuser.permissionRegisters.map((perm: any) => ({
                        id: perm.id,
                        formId: perm.formId,
                        name: perm.form.name,
                        group: perm.form.group,
                        subgroup: perm.form.subgroup,
                        type: {
                            add: perm.add,
                            edit: perm.edit,
                            delete: perm.delete,
                            view: perm.view,
                            viewatt: perm.viewatt,
                        },
                    }));
                    // console.log('transformedPermissions2', transformedPermissions);
                    await setAuthorization(transformedPermissions || null);
                } else {
                    await setAuthorization(null);
                }

                const newSession = {
                    companyId: data.companyId,
                    companyName: data.companyName,
                    companyLogo: data.companyLogo,
                    isSuperAdmin: cuser.superAdmin,
                    isAdmin: cuser.isAdmin,
                    testUser: cuser.testUser,
                    auth: transformedPermissions,
                    forms: (cuser as any).forms,
                };

                await setSessionAppStore(newSession);
                // console.log('Selected Company Called', data.companyId, newSession);

                value === false && setisProcessing(false);
                type = true;
            }
        } catch (e) {
            const error = e as AxiosError;
            console.log(error.message);
            value === false && setisProcessing(false);
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return type;
        }
    };

    const getCompanyCookie = async () => {
        let type: boolean = false;
        try {
            const { data } = await axios.get('/api/company');
            // console.log(data, count);
            if (data) {
                const { message } = data;
                if (message === 'Not Set') {
                    type = false;
                } else {
                    type = true;
                }
            }
        } catch (e) {
            const error = e as AxiosError;
            console.log(error.message);
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return type;
        }
    };

    const onProcessing = async (value: boolean) => {
        await setisProcessing(value);
    };

    const onClearCompany = async () => {
        clearSessionAppStore();
        setcompanyId(0);
        setIsAlertFetched(false);
        setcompanyName('');
        setcompanyLogo('');
        setisCookieExists(false);
    };

    const onCompanyPopulate = async (value: number) => {
        await setcompanyCount(value);
    };

    useEffect(() => {
        // console.log('router called', pathname, companyId, initialUser, authorization, user);
        // const comp = getSession();
        // console.log('getSession', comp);
        checkAuthorization('router');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    // Keep this intact
    useEffect(() => {
        if (initialUser) {
            checkAuthorization('initial');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialUser, companyId, pathname]);

    // Keep this intact
    useEffect(() => {
        if (companyId > 0) {
            if (isAlertFetched === false) {
                // Fetch Alert from action
                let alertCount = 0;
                let alertValue = '';

                setIsAlertFetched(true);

                getAllVehicles(companyId).then((results) => {
                    // if (results) {
                    //     console.log('data', results);
                    // }
                    if (results.filter((d: any) => d.RT === 3).length > 0) {
                        alertValue += `Road Tax is Expired for ${results.filter((d: any) => d.RT === 3).length} vehicle${results.filter((d: any) => d.RT === 3).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.FT === 3).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}Fitness is Expired for ${results.filter((d: any) => d.FT === 3).length} vehicle${results.filter((d: any) => d.FT === 3).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.IN === 3).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}Insurance is Expired for ${results.filter((d: any) => d.IN === 3).length} vehicle${results.filter((d: any) => d.IN === 3).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.NP === 3).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}National Permit is Expired for ${results.filter((d: any) => d.NP === 3).length} vehicle${results.filter((d: any) => d.NP === 3).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.SP === 3).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}State Permit is Expired for ${results.filter((d: any) => d.SP === 3).length} vehicle${results.filter((d: any) => d.SP === 3).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.AT === 3).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}Authorization is Expired for ${results.filter((d: any) => d.AT === 3).length} vehicle${results.filter((d: any) => d.AT === 3).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.RT === 2).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}Road Tax will Soon Expire for ${results.filter((d: any) => d.RT === 2).length} vehicle${results.filter((d: any) => d.RT === 2).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.FT === 2).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}Fitness will Soon Expire for ${results.filter((d: any) => d.FT === 2).length} vehicle${results.filter((d: any) => d.FT === 2).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.IN === 2).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}Insurance will Soon Expire for ${results.filter((d: any) => d.IN === 2).length} vehicle${results.filter((d: any) => d.IN === 2).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.NP === 2).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}National Permit will Soon Expire for ${results.filter((d: any) => d.NP === 2).length} vehicle${results.filter((d: any) => d.NP === 2).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.SP === 2).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}State Permit will Soon Expire for ${results.filter((d: any) => d.SP === 2).length} vehicle${results.filter((d: any) => d.SP === 2).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount <= 1 && results.filter((d: any) => d.AT === 2).length > 0) {
                        alertValue += `${alertCount > 0 ? '~' : ''}Authorization will Soon Expire for ${results.filter((d: any) => d.AT === 2).length} vehicle${results.filter((d: any) => d.AT === 2).length > 1 ? '(s)' : ''}`;
                        alertCount += 1;
                    }

                    if (alertCount >= 2) {
                        alertValue += ' ...';
                    }

                    setAlertData(alertValue);
                });

                setIsAlertShown(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const checkAuthorization = (strCalledFrom: string) => {
        const restrictedPaths = ['/confidential/company', '/confidential/authorization'];
        const comp = getSession();

        // console.log('strCalledFrom', strCalledFrom, superAdmin, comp);

        if (comp) {
            // This means super admin/ admin/ normal user loggedin and company selected
            // console.log('comp', comp, pathname);
            if (comp.companyId && restrictedPaths.includes(pathname)) {
                if ((comp.isAdmin || comp.isSuperAdmin) && pathname === '/confidential/authorization') {
                    // Allow this path
                } else {
                    console.log('Confidential Path2 Found!', pathname, comp.companyId, comp.isSuperAdmin, 'Redirecting to /');
                    router.push('/');
                }
            } else {
                // Its Optional to Check with zustand session data as we have already handled in Sidebar and in individual page
            }
        } else if (superAdmin && !restrictedPaths.includes(pathname) && pathname !== '/') {
            // This means super admin loggedin and company not selected
            console.log('Only Confidential Allowed Here!', pathname, superAdmin, 'Redirecting to /');
            router.push('/');
        }
    };

    useEffect(() => {
        if (initialUser === false) {
            (async function () {
                const isFound = await getCompanyCookie();
                setisCookieExists(isFound);
            })();
        }
        if (user) {
            // console.log('Login User:', user.id);
            user?.getSessions().then((sa: any) => {
                // console.log(sa);
                sa.forEach(async (session: any) => {
                    // console.log('user id', user.id, 'session id', session.id);
                    // console.log(session);
                    const newSession = {
                        count: count ? count + 1 : 1,
                        clerkId: user.id,
                        sessionId: session.id,
                        pathName: pathname,
                        updatedTime: cdate(),
                        update: true,
                        systemName: `${session.latestActivity.ipAddress} , ${session.latestActivity.city} - ${session.latestActivity.country} - ${session.latestActivity.deviceType}${
                            session.latestActivity.isMobile ? ' [Mobile]' : ''
                        } - ${session.latestActivity.browserName} [${session.latestActivity.browserVersion}]`,
                    };
                    await setSession(newSession);

                    if (initialUser === false) {
                        const singleCompany: boolean = await setCompanyInitially(user.id);
                        // console.log('xxxxxxx', companyId, initialUser, user.id, singleCompany);

                        if (singleCompany === true) {
                            const payload = {
                                companyId,
                                companyName,
                                companyLogo,
                                type: true,
                            };
                            // console.log('Single Company', true);

                            try {
                                await fetch(`/api/company`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify(payload),
                                });
                            } catch (err) {
                                console.log(err);
                            }
                        } else {
                            // console.log('Multi Company', true);
                            const value = await getSelectedCompany(true);
                            // console.log('Company', companyId, value);

                            if (value === false) {
                                await onClearCompany();
                            }
                        }

                        await setinitialUser(true);
                        if (count === 0) {
                            showToast({ title: 'Signed in successfully.', position: 'bottom-right', type: 'success' });
                        }
                    } else {
                        // console.log('Only Path Changed', pathname);
                    }

                    await setinitialLoading(false);
                    // console.log('Updated on user/path change');
                });
            });
        }
        // else if (pathname !== '/') {
        //     // console.log('User loggedout', user, 'companyId', companyId, pathname);
        //     console.log('??????', initialUser);
        //     router.push('/');
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, pathname]);

    useEffect(() => {
        if (update === true && companyId) {
            // console.log('Session Resetted:', update, count);
            const updateSessionAndReset = async () => {
                await updateSession({
                    clerkId: clerkId!,
                    sessionId: sessionId!,
                    lastConnectTime: updatedTime!,
                    sessionEndTime: null,
                    sessionEndType: null,
                    companyId: companyId > 0 ? companyId : null,
                    systemName: systemName!,
                });
                resetSession();
            };

            updateSessionAndReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, companyId]);

    useEffect(() => {
        // This is done as user is evaluated to null value initially and sign-in and sign-out buttons are visible for 1-2 seconds
        setTimeout(() => {
            setinitialLoading(false);
        }, 2000);

        // await new Promise(resolve => setTimeout(resolve,2000))
        setTimeout(() => {
            setisProcessing(false);
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // console.log('Current Theme is: ' + isDark);
        const themeElement = document.getElementById('theme') as HTMLAnchorElement;
        themeElement.href = `https://cdn.syncfusion.com/ej2/26.1.35/bootstrap5${isDark ? '-dark' : ''}.css`;
        // themeElement.href = `/syncfusion/bootstrap5${isDark ? '-dark' : ''}.css`;
    }, [isDark]);

    // if (isProcessing) {
    //     return <Loading />;
    // }

    return (
        <>
            {/* <Script id="theme">
            </Script> */}
            {/* BEGIN MAIN CONTAINER */}
            <div
                className="relative flex min-h-screen flex-col bg-cover bg-no-repeat bg-fixed"
                style={{
                    backgroundImage,
                }}
            >
                <Overlay />
                <ScrollToTop />
                <>
                    {/* BEGIN APP SETTING LAUNCHER */}
                    {user && companyId > 0 && <Setting />}
                    {/* END APP SETTING LAUNCHER */}

                    {user &&
                        (skipLayoutPaths.includes(pathname) ? (
                            <MainContainer>
                                <ContentAnimation>{children}</ContentAnimation>
                            </MainContainer>
                        ) : (
                            <MainContainer>
                                <>
                                    {/* BEGIN SIDEBAR */}
                                    <Sidebar
                                        companyId={companyId}
                                        superAdmin={superAdmin}
                                        admin={admin}
                                        testUser={testUser}
                                        initialUser={initialUser}
                                        companyCount={companyCount}
                                        auth={authorization}
                                        forms={forms}
                                    />
                                    {/* END SIDEBAR */}
                                    <div className="main-content flex h-full min-h-screen flex-col">
                                        {/* BEGIN TOP NAVBAR */}
                                        {superAdmin !== undefined && companyId > 0 && (
                                            <Header
                                                companyId={companyId}
                                                companyName={companyName}
                                                companyLogo={companyLogo}
                                                superAdmin={superAdmin}
                                                admin={admin}
                                                testUser={testUser}
                                                onProcessing={onProcessing}
                                                onClearCompany={onClearCompany}
                                                isAlertShown={isAlertShown}
                                                setIsAlertShown={setIsAlertShown}
                                                alertData={alertData}
                                                auth={authorization}
                                                forms={forms}
                                            />
                                        )}

                                        <ErrorBoundary fallback={<div>Something went wrong</div>}>
                                            {companyId === 0 && (
                                                <NavbarPlant
                                                    clerkId={clerkId}
                                                    superAdmin={superAdmin}
                                                    admin={admin}
                                                    testUser={testUser}
                                                    isCookieExists={isCookieExists}
                                                    onValueChange={getSelectedCompany}
                                                    onProcessing={onProcessing}
                                                    onCompanyPopulate={onCompanyPopulate}
                                                />
                                            )}
                                        </ErrorBoundary>
                                        {/* END TOP NAVBAR */}

                                        {/* BEGIN CONTENT AREA */}
                                        {/* {companyId > 0 && (isProcessing ? <LoadingNew /> : <ContentAnimation>{children}</ContentAnimation>)} */}

                                        {/* {isProcessing === true && <div className="flex grow items-center justify-center">{<LoadingNew />}</div>}
                                        {isProcessing === false && <ContentAnimation>{children}</ContentAnimation>} */}
                                        <div className="flex-grow">
                                            <ContentAnimation>{children}</ContentAnimation>
                                        </div>

                                        {/* END CONTENT AREA */}

                                        {/* BEGIN FOOTER */}
                                        <Footer textDark={true} />
                                        {/* END FOOTER */}
                                        <Portals />
                                    </div>
                                </>
                            </MainContainer>
                        ))}
                    {/* {user && companyId === 0 && (
                        <div className="main-content flex min-h-screen flex-col">
                            <NavbarPlant clerkId={clerkId} superAdmin={superAdmin} admin={admin} isCookieExists={isCookieExists} onValueChange={getSelectedCompany} onProcessing={onProcessing} />
                            <div className="flex grow items-center justify-center">{isProcessing ? <LoadingNew /> : children}</div>
                            <Footer textDark={false} />
                            <Portals />
                        </div>
                    )} */}
                    {!user && (
                        <div className="main-content flex min-h-screen flex-col">
                            <Navbar showButtons={!initialLoading} isLoading={false} />
                            <div className="flex grow items-center justify-center">{isProcessing ? <LoadingNew /> : children}</div>
                            <Footer textDark={false} />
                            <Portals />
                        </div>
                    )}
                </>
            </div>
        </>
    );
}
