import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface SessionType {
    clerkId: string | null;
    sessionId: string | null;
    updatedTime: Date | null;
    pathName: string | null;
    systemName: string | null;
    update: Boolean;
    count: number;
    setSession: (session: any) => void;
    resetSession: () => void;
    clearSession: () => void;
}

const initialState = {
    clerkId: null,
    sessionId: null,
    updatedTime: null,
    pathName: null,
    systemName: null,
    update: false,
    count: 0,
};

export const useSessionStore = create<SessionType>()(
    persist(
        (set) => ({
            ...initialState,
            setSession: (session) => {
                // console.log(session);
                set({
                    count: session.count,
                    clerkId: session.clerkId,
                    sessionId: session.sessionId,
                    pathName: session.pathName,
                    systemName: session.systemName,
                    updatedTime: session.updatedTime,
                    update: session.update,
                });
            },
            clearSession: () => {
                set(initialState);
            },
            resetSession: () => {
                set({
                    update: false,
                });
            },
        }),
        {
            name: 'auth',
            storage: createJSONStorage(() => sessionStorage),
            // getStorage: () => sessionStorage,
        },
    ),
);
