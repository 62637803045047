import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

interface AlertOptions {
    title?: string;
    message?: string;
    type?: SweetAlertIcon;
    confirmText?: string;
    showCancelButton?: boolean;
    timerDuration?: number;
    target?: string | HTMLElement;
}

export const showAlert = async (options: AlertOptions = {}): Promise<SweetAlertResult> => {
    // Provide default values if not specified in options
    let { title = 'Message Title!', message = 'Message <b>Text</b>', type = 'success', confirmText = 'OK', showCancelButton = false, timerDuration = 0, target } = options;

    if (title === 'Connection Issue!') {
        message = 'Check your internet connection. <br/><b>OR</b><br/> Try to refresh the page.';
        type = 'error';
    }

    return new Promise<SweetAlertResult>((resolve) => {
        Swal.fire({
            icon: type,
            title,
            html: message,
            timer: timerDuration,
            timerProgressBar: timerDuration > 0,
            padding: '1em',
            customClass: {
                container: 'sweet-alerts',
            },
            showCancelButton,
            confirmButtonText: confirmText,
            reverseButtons: true,
            target,
            didOpen: () => {
                // Swal.showLoading();
                // const b: any = Swal.getHtmlContainer()?.querySelector('b');
                // timerInterval = setInterval(() => {
                //     // b.textContent = Swal.getTimerLeft();
                // }, 100);
            },
            // willClose: () => {
            //     clearInterval(timerInterval);
            // },
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                // console.log('I was closed by the timer');
            } else {
                resolve(result);
            }
        });
    });
};
