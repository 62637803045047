import IconX from '@/components/icon/icon-x';
import React from 'react';
import IconInfoCircle from '../icon/icon-info-circle';
import { useRouter } from 'next/navigation';

interface AlertsWithIconProps {
    msg: string;
    msg2: string | null;
    close?: boolean;
    type?: string | null;
    cursor?: boolean | null;
    setIsAlertShown?: React.Dispatch<React.SetStateAction<boolean>>;
}
const AlertsWithIcon: React.FC<AlertsWithIconProps> = ({ msg, msg2, close = false, type = 'warning', cursor = false, setIsAlertShown }: any) => {
    let typeText = '';
    switch (type) {
        case 'success':
            typeText = 'Success!';
            break;
        case 'info':
            typeText = 'Info!';
            break;
        case 'warning':
            typeText = 'Warning!';
            break;
        case 'error':
            typeText = 'Error!';
            break;
        default:
            typeText = 'Info!';
            break;
    }

    const router = useRouter();

    return (
        <>
            {/* <div className="relative flex items-center rounded border border-success bg-success-light p-3.5 text-success dark:bg-success-dark-light ltr:border-l-[64px] rtl:border-r-[64px]">
                    <span className="absolute inset-y-0 m-auto size-6 text-white ltr:-left-11 rtl:-right-11">
                        <IconInfoTriangle />
                    </span>
                    <span className="ltr:pr-2 rtl:pl-2">
                        <strong className="ltr:mr-1 rtl:ml-1">Warning!</strong>Lorem Ipsum is simply dummy text of the printing.
                    </span>
                    <button type="button" className="hover:opacity-80 ltr:ml-auto rtl:mr-auto">
                        <IconX className="size-5" />
                    </button>
                </div>
                <div className="relative flex items-center rounded border border-dark bg-dark-light p-3.5 text-dark dark:border-white-light/20 dark:bg-dark-dark-light dark:text-white-light ltr:border-r-[64px] rtl:border-l-[64px]">
                    <span className="absolute inset-y-0 m-auto size-6 text-white ltr:-right-11 rtl:-left-11">
                        <IconSettings />
                    </span>
                    <span className="ltr:pr-2 rtl:pl-2">
                        <strong className="ltr:mr-1 rtl:ml-1">Warning!</strong>Lorem Ipsum is simply dummy text of the printing.
                    </span>
                    <button type="button" className="hover:opacity-80 ltr:ml-auto rtl:mr-auto">
                        <IconX className="size-5" />
                    </button>
                </div> */}

            <div className="relative flex items-center rounded border border-danger bg-danger-light px-2.5 py-0 text-danger before:absolute before:top-1/2 before:-mt-2 before:inline-block before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-inherit dark:bg-danger-dark-light ltr:border-r-[64px] ltr:before:right-0 rtl:border-l-[64px] rtl:before:left-0 rtl:before:rotate-180">
                <span className="absolute inset-y-0 m-auto size-6 text-white ltr:-right-11 rtl:-left-11">
                    <IconInfoCircle />
                </span>
                <span
                    className={`ltr:pr-2 rtl:pl-2 ${cursor && 'cursor-pointer'}`}
                    onClick={() => {
                        setIsAlertShown(false);
                        router.push('/master/vehicle');
                    }}
                >
                    <strong className="ltr:mr-1 rtl:ml-1">{typeText}</strong>
                    {msg}
                    {msg2 !== '' ? <p className="text-right font-semibold italic">{msg2}</p> : ''}
                </span>
                <button
                    type="button"
                    className={`hover:opacity-80 ltr:ml-auto rtl:mr-auto ${close === false ? 'hidden' : ''}`}
                    onClick={(e) => {
                        setIsAlertShown(false);
                    }}
                >
                    <IconX className="size-5" />
                </button>
            </div>
        </>
    );
};

export default AlertsWithIcon;
