interface Props {
    textDark: boolean;
}

const Footer = ({ textDark }: Props) => {
    return (
        <div className={`pl-3 pb-4 text-center ${textDark ? 'text-white-dark' : 'text-white'} ltr:sm:text-left rtl:sm:text-right`}>
            © {new Date().getFullYear()}. Hepta Infotech Services LLP All rights reserved.
        </div>
    );
};

export default Footer;
