/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
'use client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { IRootState } from '@/store';
import { toggleTheme, toggleSidebar } from '@/store/themeConfigSlice';
import Dropdown from '@/components/dropdown';
import IconMenu from '@/components/icon/icon-menu';
import IconSearch from '@/components/icon/icon-search';
import IconXCircle from '@/components/icon/icon-x-circle';
import IconSun from '@/components/icon/icon-sun';
import IconMoon from '@/components/icon/icon-moon';
import IconLaptop from '@/components/icon/icon-laptop';
import IconInfoCircle from '@/components/icon/icon-info-circle';
import IconBellBing from '@/components/icon/icon-bell-bing';
import IconUser from '@/components/icon/icon-user';
import IconLogout from '@/components/icon/icon-logout';
import IconMenuDashboard from '@/components/icon/menu/icon-menu-dashboard';
import IconCaretDown from '@/components/icon/icon-caret-down';
import IconMenuApps from '@/components/icon/menu/icon-menu-apps';
import IconMenuComponents from '@/components/icon/menu/icon-menu-components';
import IconMenuElements from '@/components/icon/menu/icon-menu-elements';
import IconMenuDatatables from '@/components/icon/menu/icon-menu-datatables';
import IconMenuForms from '@/components/icon/menu/icon-menu-forms';
import IconMenuPages from '@/components/icon/menu/icon-menu-pages';
import IconMenuMore from '@/components/icon/menu/icon-menu-more';
import { getTranslation } from '@/i18n';
import Image from 'next/image';
import { useClerk, useUser } from '@clerk/nextjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useSessionStore } from '@/state/clerkssn';
import { usePathname, useRouter } from 'next/navigation';
import axios, { AxiosError } from 'axios';
import { showToast } from '@/constants/sweetalerts';
import { useAppStore } from '@/state/company';
import AlertsWithIcon from '../fleetnet/alerts';
import { Authorization, hasPagePermission, ReleaseForms } from '@/constants/general';

interface HeaderProps {
    companyId: number;
    companyName: string;
    companyLogo?: string;
    superAdmin: boolean;
    admin: boolean;
    testUser: boolean;
    onProcessing: (value: boolean) => void;
    onClearCompany: () => void;
    isAlertShown: boolean;
    setIsAlertShown: React.Dispatch<React.SetStateAction<boolean>>;
    alertData?: string;
    auth: Authorization[] | null;
    forms: ReleaseForms[] | null;
}

const Header = ({ companyId, companyName, companyLogo, superAdmin, admin, testUser, onProcessing, onClearCompany, isAlertShown, setIsAlertShown, alertData, auth, forms }: HeaderProps) => {
    const pathname = usePathname();
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchTranslation = async () => {
            const { t } = await getTranslation();
            setT(() => t);
        };
        fetchTranslation();
    }, []);

    const [t, setT] = useState<(key: string) => any>(() => (key: string) => key);

    const router = useRouter();
    const user = useUser();
    const { signOut } = useClerk();

    const { clearSession } = useSessionStore();
    const { clearSession: clearSessionAppStore, setSession: setSessionAppStore } = useAppStore();
    const [isLoader, setIsLoader] = useState(false);

    const [msg, msg2] = alertData!.split('~');

    const message1 = msg || '';
    const message2 = msg2 || '';

    useEffect(() => {
        const selector = document.querySelector('ul.horizontal-menu a[href="' + window.location.pathname + '"]');
        if (selector) {
            const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
            for (let i = 0; i < all.length; i++) {
                all[0]?.classList.remove('active');
            }

            const allLinks = document.querySelectorAll('ul.horizontal-menu a.active');
            for (let i = 0; i < allLinks.length; i++) {
                const element = allLinks[i];
                element?.classList.remove('active');
            }
            selector?.classList.add('active');

            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele?.classList.add('active');
                    });
                }
            }
        }
    }, [pathname]);

    const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl';

    const themeConfig = useSelector((state: IRootState) => state.themeConfig);

    const [notifications, setNotifications] = useState([
        {
            id: 1,
            profile: 'user-profile.jpeg',
            message: '<strong class="text-sm mr-1">John Doe</strong>invite you to <strong>Prototyping</strong>',
            time: '45 min ago',
        },
        {
            id: 2,
            profile: 'profile-34.jpeg',
            message: '<strong class="text-sm mr-1">Adam Nolan</strong>mentioned you to <strong>UX Basics</strong>',
            time: '9h Ago',
        },
        {
            id: 3,
            profile: 'profile-16.jpeg',
            message: '<strong class="text-sm mr-1">Anna Morgan</strong>Upload a file',
            time: '9h Ago',
        },
    ]);

    const removeNotification = (value: number) => {
        setNotifications(notifications.filter((user) => user.id !== value));
    };

    const [search, setSearch] = useState(false);

    const removeCompany = async () => {
        try {
            // console.log('Company selected', selectedCompanyId);
            const payload = {
                companyId: '',
                companyName: '',
                type: false,
            };
            await axios.post('/api/company', payload);
        } catch (e) {
            const error = e as AxiosError;
            console.log(error.message);
        }
    };

    return (
        <header className={`z-40 ${themeConfig.semidark && themeConfig.menu === 'horizontal' ? 'dark' : ''}`}>
            <div className="shadow-sm">
                <div className="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-black">
                    <div className="horizontal-logo flex items-center justify-between lg:hidden ltr:mr-2 rtl:ml-2">
                        <Link href="/" className="main-logo flex shrink-0 items-center">
                            <Image className="inline w-8 ltr:-ml-1 rtl:-mr-1" src="/assets/images/logo.svg" alt="logo" width={80} height={20} />
                            <span className="hidden align-middle text-2xl  font-semibold  transition-all duration-300 dark:text-white-light md:inline ltr:ml-1.5 rtl:mr-1.5">FleetNet</span>
                        </Link>
                        <button
                            type="button"
                            className="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden ltr:ml-2 rtl:mr-2"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconMenu className="size-5" />
                        </button>
                    </div>

                    <div className="flex items-center space-x-1.5 dark:text-[#d0d2d6] sm:flex-1 lg:space-x-2 ltr:ml-auto ltr:sm:ml-0 rtl:mr-auto rtl:space-x-reverse sm:rtl:mr-0">
                        <div className="sm:ltr:mr-auto sm:rtl:ml-auto">
                            <form
                                className={`${search && '!block'} absolute inset-x-0 top-1/2 z-10 mx-4 hidden -translate-y-1/2 sm:relative sm:top-0 sm:mx-0 sm:block sm:translate-y-0`}
                                onSubmit={() => setSearch(false)}
                            >
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="peer form-input bg-gray-100 placeholder:tracking-widest sm:bg-transparent ltr:px-9 ltr:sm:pr-4 rtl:px-9 rtl:sm:pl-4"
                                        placeholder="Search..."
                                    />
                                    <button type="button" className="absolute inset-0 size-9 appearance-none peer-focus:text-primary ltr:right-auto rtl:left-auto">
                                        <IconSearch className="mx-auto" />
                                    </button>
                                    <button type="button" className="absolute top-1/2 block -translate-y-1/2 hover:opacity-80 sm:hidden ltr:right-2 rtl:left-2" onClick={() => setSearch(false)}>
                                        <IconXCircle />
                                    </button>
                                </div>
                            </form>
                            <button
                                type="button"
                                onClick={() => setSearch(!search)}
                                className="rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 dark:bg-dark/40 dark:hover:bg-dark/60 sm:hidden"
                            >
                                <IconSearch className="mx-auto size-4.5 dark:text-[#d0d2d6]" />
                            </button>
                        </div>
                        <div className="inline-flex items-center gap-2">
                            <span
                                className={`m-auto inline-block size-6 animate-spin rounded-full border-[3px] border-success border-l-transparent align-middle ${isLoader ? 'flex' : 'hidden'}`}
                            ></span>
                            {companyLogo && (
                                <>
                                    {hasPagePermission(auth || [], superAdmin || false, admin || false, 1, testUser, forms, 'view').view && (
                                        // View permission for Vehicle Register
                                        <div className={`hidden items-center ${isAlertShown && alertData !== '' ? 'lg:block' : 'lg:hidden'}`}>
                                            <AlertsWithIcon msg={message1} msg2={message2} cursor={true} close={true} setIsAlertShown={setIsAlertShown} />
                                        </div>
                                    )}

                                    <div className="md:hidden">
                                        <Image
                                            className="size-6 max-w-none rounded-full"
                                            src={companyLogo}
                                            alt="Company_Logo"
                                            style={{ width: '36px', height: '36px', borderRadius: '50%' }}
                                            width={100}
                                            height={100}
                                        />
                                    </div>
                                    <div className="hidden md:block" style={{ height: '35px', width: '150px' }}>
                                        <Image
                                            className="rounded-md object-fill"
                                            src={companyLogo}
                                            alt="Company_Logo"
                                            style={{ objectFit: 'fill', width: '100%', height: '100%' }} // fill/contain/cover/scale-down
                                            width={100}
                                            height={100}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="font-bold dark:hover:text-white">{companyName}</div>
                        </div>
                        <div style={{ height: '37px' }}></div>
                        <div>
                            {themeConfig.theme === 'light' ? (
                                <button
                                    className={`${
                                        themeConfig.theme === 'light' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('dark'))}
                                >
                                    <IconSun />
                                </button>
                            ) : (
                                ''
                            )}
                            {themeConfig.theme === 'dark' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'dark' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('system'))}
                                >
                                    <IconMoon />
                                </button>
                            )}
                            {themeConfig.theme === 'system' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'system' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => dispatch(toggleTheme('light'))}
                                >
                                    <IconLaptop />
                                </button>
                            )}
                        </div>

                        <div className={`dropdown shrink-0 ${superAdmin === false ? 'hidden' : ''}`}>
                            <Dropdown
                                offset={[0, 8]}
                                placement={`${isRtl ? 'bottom-start' : 'bottom-end'}`}
                                btnClassName="relative block p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                                button={
                                    <span>
                                        <IconBellBing />
                                        <span className="absolute top-0 flex size-3 ltr:right-0 rtl:left-0">
                                            <span className="absolute -top-[3px] inline-flex size-full animate-ping rounded-full bg-success/50 opacity-75 ltr:-left-[3px]"></span>
                                            <span className="relative inline-flex size-[6px] rounded-full bg-success"></span>
                                        </span>
                                    </span>
                                }
                            >
                                <ul className="w-[300px] divide-y !py-0 text-dark dark:divide-white/10 dark:text-white-dark sm:w-[350px]">
                                    <li onClick={(e) => e.stopPropagation()}>
                                        <div className="flex items-center justify-between px-4 py-2 font-semibold">
                                            <h4 className="text-lg">Notification</h4>
                                            {notifications.length ? <span className="badge bg-primary/80">{notifications.length}New</span> : ''}
                                        </div>
                                    </li>
                                    {notifications.length > 0 ? (
                                        <>
                                            {notifications.map((notification) => {
                                                return (
                                                    <li key={notification.id} className="dark:text-white-light/90" onClick={(e) => e.stopPropagation()}>
                                                        <div className="group flex items-center px-4 py-2">
                                                            <div className="grid place-content-center rounded">
                                                                <div className="relative size-12">
                                                                    <Image
                                                                        className="size-12 rounded-full object-cover"
                                                                        alt="profile"
                                                                        src={`/assets/images/${notification.profile}`}
                                                                        width={48}
                                                                        height={48}
                                                                    />
                                                                    <span className="absolute bottom-0 right-[6px] block size-2 rounded-full bg-success"></span>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-auto ltr:pl-3 rtl:pr-3">
                                                                <div className="ltr:pr-3 rtl:pl-3">
                                                                    <h6
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: notification.message,
                                                                        }}
                                                                    ></h6>
                                                                    <span className="block text-xs font-normal dark:text-gray-500">{notification.time}</span>
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="text-neutral-300 opacity-0 hover:text-danger group-hover:opacity-100 ltr:ml-auto rtl:mr-auto"
                                                                    onClick={() => removeNotification(notification.id)}
                                                                >
                                                                    <IconXCircle />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <li>
                                                <div className="p-4">
                                                    <button className="btn btn-primary btn-sm block w-full">Read All Notifications</button>
                                                </div>
                                            </li>
                                        </>
                                    ) : (
                                        <li onClick={(e) => e.stopPropagation()}>
                                            <button type="button" className="!grid min-h-[200px] place-content-center text-lg hover:!bg-transparent">
                                                <div className="mx-auto mb-4 rounded-full ring-4 ring-primary/30">
                                                    <IconInfoCircle fill={true} className="size-10 text-primary" />
                                                </div>
                                                No data available.
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </Dropdown>
                        </div>
                        <div className="dropdown flex">
                            <Dropdown
                                offset={[0, 8]}
                                placement={`${isRtl ? 'bottom-start' : 'bottom-end'}`}
                                btnClassName="relative group block"
                                button={
                                    <Image
                                        className="size-9 rounded-full object-cover saturate-50 group-hover:saturate-100"
                                        src={user.user?.imageUrl || '/assets/images/Man_Avatar.png'}
                                        alt="userProfile"
                                        width={48}
                                        height={48}
                                    />
                                }
                            >
                                <ul className="w-[330px] !py-0 font-semibold text-dark dark:text-white-dark dark:hover:text-white-light/90">
                                    <li>
                                        <div className="flex items-center p-4">
                                            <Image className="size-10 rounded-md object-cover" src={user.user?.imageUrl || '/assets/images/Man_Avatar.png'} alt="userProfile" width={48} height={48} />
                                            <div className="truncate ltr:pl-4 rtl:pr-4">
                                                <h4 className="text-base" style={{ display: 'flex', alignItems: 'center' }}>
                                                    {user.user?.fullName}
                                                    {superAdmin === true ? (
                                                        <span className="rounded bg-danger px-1 text-xs text-white ltr:ml-2 rtl:ml-2">Super Admin</span>
                                                    ) : admin === true ? (
                                                        <span className="rounded bg-success-light px-1 text-xs text-success ltr:ml-2 rtl:ml-2">Admin</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </h4>
                                                <button type="button" className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white">
                                                    {user.user?.emailAddresses[0].emailAddress}
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <Link href="/users/clerk_profile" className="dark:hover:text-white">
                                            <FontAwesomeIcon icon={faUserCircle} className="size-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                            Profile (Clerk)
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/users/profile" className="dark:hover:text-white">
                                            <IconUser className="size-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                            Profile
                                        </Link>
                                    </li>
                                    {superAdmin === true ? (
                                        <li>
                                            <Link
                                                href="/"
                                                className="font-bold text-red-700 dark:hover:text-white"
                                                onClick={async () => {
                                                    setIsLoader(true);
                                                    await onProcessing(true);
                                                    await setTimeout(async () => {
                                                        await removeCompany();
                                                        await onClearCompany();
                                                        await clearSessionAppStore();
                                                        await setSessionAppStore(null);
                                                        await onProcessing(false);
                                                    }, 1000);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTruckMoving} className="size-4.5 shrink-0 text-red-700 ltr:mr-2 rtl:ml-2" />
                                                Change Company
                                            </Link>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {/* <li>
                                        <Link href="/auth/boxed-lockscreen" className="dark:hover:text-white">
                                            <IconLockDots className="size-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                            Lock Screen
                                        </Link>
                                    </li> */}
                                    <li className="border-t border-white-light dark:border-white-light/10">
                                        <Link
                                            href={'/'}
                                            onClick={async () => {
                                                setIsLoader(true);
                                                await onProcessing(true);
                                                await removeCompany();
                                                await signOut(async () => {
                                                    router.push('/');
                                                    // router.refresh();
                                                    await clearSession();
                                                    await clearSessionAppStore();
                                                    await onProcessing(false);
                                                    showToast({ title: 'Signed out successfully.', position: 'bottom-right', type: 'success' });
                                                    // location.reload();
                                                });
                                            }}
                                            className="!py-3 text-danger"
                                        >
                                            <IconLogout className="size-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />
                                            Sign Out
                                        </Link>
                                    </li>
                                </ul>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {/* horizontal menu */}
                <ul className="horizontal-menu hidden border-t border-[#ebedf2] bg-white px-6 py-1.5 font-semibold text-black dark:border-[#191e3a] dark:bg-black dark:text-white-dark lg:space-x-1.5 xl:space-x-8 rtl:space-x-reverse">
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuDashboard className="shrink-0" />
                                <span className="px-1">{t('dashboard')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li>
                                <Link href="/">{t('sales')}</Link>
                            </li>
                            <li>
                                <Link href="/analytics">{t('analytics')}</Link>
                            </li>
                            <li>
                                <Link href="/finance">{t('finance')}</Link>
                            </li>
                            <li>
                                <Link href="/crypto">{t('crypto')}</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuApps className="shrink-0" />
                                <span className="px-1">{t('apps')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li>
                                <Link href="/apps/chat">{t('chat')}</Link>
                            </li>
                            <li>
                                <Link href="/apps/mailbox">{t('mailbox')}</Link>
                            </li>
                            <li>
                                <Link href="/apps/todolist">{t('todo_list')}</Link>
                            </li>
                            <li>
                                <Link href="/apps/notes">{t('notes')}</Link>
                            </li>
                            <li>
                                <Link href="/apps/scrumboard">{t('scrumboard')}</Link>
                            </li>
                            <li>
                                <Link href="/apps/contacts">{t('contacts')}</Link>
                            </li>
                            <li className="relative">
                                <button type="button">
                                    {t('invoice')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/apps/invoice/list">{t('list')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/apps/invoice/preview">{t('preview')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/apps/invoice/add">{t('add')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/apps/invoice/edit">{t('edit')}</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link href="/apps/calendar">{t('calendar')}</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuComponents className="shrink-0" />
                                <span className="px-1">{t('components')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li>
                                <Link href="/components/tabs">{t('tabs')}</Link>
                            </li>
                            <li>
                                <Link href="/components/accordions">{t('accordions')}</Link>
                            </li>
                            <li>
                                <Link href="/components/modals">{t('modals')}</Link>
                            </li>
                            <li>
                                <Link href="/components/cards">{t('cards')}</Link>
                            </li>
                            <li>
                                <Link href="/components/carousel">{t('carousel')}</Link>
                            </li>
                            <li>
                                <Link href="/components/countdown">{t('countdown')}</Link>
                            </li>
                            <li>
                                <Link href="/components/counter">{t('counter')}</Link>
                            </li>
                            <li>
                                <Link href="/components/sweetalert">{t('sweet_alerts')}</Link>
                            </li>
                            <li>
                                <Link href="/components/timeline">{t('timeline')}</Link>
                            </li>
                            <li>
                                <Link href="/components/notifications">{t('notifications')}</Link>
                            </li>
                            <li>
                                <Link href="/components/media-object">{t('media_object')}</Link>
                            </li>
                            <li>
                                <Link href="/components/list-group">{t('list_group')}</Link>
                            </li>
                            <li>
                                <Link href="/components/pricing-table">{t('pricing_tables')}</Link>
                            </li>
                            <li>
                                <Link href="/components/lightbox">{t('lightbox')}</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuElements className="shrink-0" />
                                <span className="px-1">{t('elements')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li>
                                <Link href="/elements/alerts">{t('alerts')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/avatar">{t('avatar')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/badges">{t('badges')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/breadcrumbs">{t('breadcrumbs')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/buttons">{t('buttons')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/buttons-group">{t('button_groups')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/color-library">{t('color_library')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/dropdown">{t('dropdown')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/infobox">{t('infobox')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/jumbotron">{t('jumbotron')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/loader">{t('loader')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/pagination">{t('pagination')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/popovers">{t('popovers')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/progress-bar">{t('progress_bar')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/search">{t('search')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/tooltips">{t('tooltips')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/treeview">{t('treeview')}</Link>
                            </li>
                            <li>
                                <Link href="/elements/typography">{t('typography')}</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuDatatables className="shrink-0" />
                                <span className="px-1">{t('tables')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li>
                                <Link href="/tables">{t('tables')}</Link>
                            </li>
                            <li className="relative">
                                <button type="button">
                                    {t('datatables')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/datatables/basic">{t('basic')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/advanced">{t('advanced')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/skin">{t('skin')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/order-sorting">{t('order_sorting')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/multi-column">{t('multi_column')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/multiple-tables">{t('multiple_tables')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/alt-pagination">{t('alt_pagination')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/checkbox">{t('checkbox')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/range-search">{t('range_search')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/export">{t('export')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/datatables/column-chooser">{t('column_chooser')}</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuForms className="shrink-0" />
                                <span className="px-1">{t('forms')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li>
                                <Link href="/forms/basic">{t('basic')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/input-group">{t('input_group')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/layouts">{t('layouts')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/validation">{t('validation')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/input-mask">{t('input_mask')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/select2">{t('select2')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/touchspin">{t('touchspin')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/checkbox-radio">{t('checkbox_and_radio')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/switches">{t('switches')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/wizards">{t('wizards')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/file-upload">{t('file_upload')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/quill-editor">{t('quill_editor')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/markdown-editor">{t('markdown_editor')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/date-picker">{t('date_and_range_picker')}</Link>
                            </li>
                            <li>
                                <Link href="/forms/clipboard">{t('clipboard')}</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuPages className="shrink-0" />
                                <span className="px-1">{t('pages')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li className="relative">
                                <button type="button">
                                    {t('users')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/users/profile">{t('profile')}</Link>
                                    </li>
                                    <li>
                                        <Link href="/users/user-account-settings">{t('account_settings')}</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link href="/pages/knowledge-base">{t('knowledge_base')}</Link>
                            </li>
                            <li>
                                <Link href="/pages/contact-us-boxed" target="_blank">
                                    {t('contact_us_boxed')}
                                </Link>
                            </li>
                            <li>
                                <Link href="/pages/contact-us-cover" target="_blank">
                                    {t('contact_us_cover')}
                                </Link>
                            </li>
                            <li>
                                <Link href="/pages/faq">{t('faq')}</Link>
                            </li>
                            <li>
                                <Link href="/pages/coming-soon-boxed" target="_blank">
                                    {t('coming_soon_boxed')}
                                </Link>
                            </li>
                            <li>
                                <Link href="/pages/coming-soon-cover" target="_blank">
                                    {t('coming_soon_cover')}
                                </Link>
                            </li>
                            <li>
                                <Link href="/pages/maintenence" target="_blank">
                                    {t('maintenence')}
                                </Link>
                            </li>
                            <li className="relative">
                                <button type="button">
                                    {t('error')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/pages/error404" target="_blank">
                                            {t('404')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/pages/error500" target="_blank">
                                            {t('500')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/pages/error503" target="_blank">
                                            {t('503')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="relative">
                                <button type="button">
                                    {t('login')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/auth/cover-login" target="_blank">
                                            {t('login_cover')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/auth/boxed-signin" target="_blank">
                                            {t('login_boxed')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="relative">
                                <button type="button">
                                    {t('register')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/auth/cover-register" target="_blank">
                                            {t('register_cover')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/auth/boxed-signup" target="_blank">
                                            {t('register_boxed')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="relative">
                                <button type="button">
                                    {t('password_recovery')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/auth/cover-password-reset" target="_blank">
                                            {t('recover_id_cover')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/auth/boxed-password-reset" target="_blank">
                                            {t('recover_id_boxed')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="relative">
                                <button type="button">
                                    {t('lockscreen')}
                                    <div className="-rotate-90 ltr:ml-auto rtl:mr-auto rtl:rotate-90">
                                        <IconCaretDown />
                                    </div>
                                </button>
                                <ul className="absolute top-0 z-10 hidden min-w-[180px] rounded bg-white p-0 py-2 text-dark shadow dark:bg-[#1b2e4b] dark:text-white-dark ltr:left-[95%] rtl:right-[95%]">
                                    <li>
                                        <Link href="/auth/cover-lockscreen" target="_blank">
                                            {t('unlock_cover')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/auth/boxed-lockscreen" target="_blank">
                                            {t('unlock_boxed')}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="menu nav-item relative">
                        <button type="button" className="nav-link">
                            <div className="flex items-center">
                                <IconMenuMore className="shrink-0" />
                                <span className="px-1">{t('more')}</span>
                            </div>
                            <div className="right_arrow">
                                <IconCaretDown />
                            </div>
                        </button>
                        <ul className="sub-menu">
                            <li>
                                <Link href="/dragndrop">{t('drag_and_drop')}</Link>
                            </li>
                            <li>
                                <Link href="/charts">{t('charts')}</Link>
                            </li>
                            <li>
                                <Link href="/font-icons">{t('font_icons')}</Link>
                            </li>
                            <li>
                                <Link href="/widgets">{t('widgets')}</Link>
                            </li>
                            <li>
                                <Link href="https://vristo.sbthemes.com" target="_blank">
                                    {t('documentation')}
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
